import $ from "jquery"

// import 'bootstrap/js/src/alert'
// import 'bootstrap/js/src/button'
// import 'bootstrap/js/src/carousel'
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/dropdown'
// import 'bootstrap/js/src/modal'
// import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/scrollspy'
// import 'bootstrap/js/src/tab'
// import 'bootstrap/js/src/toast'
// import 'bootstrap/js/src/tooltip'

window.addEventListener(
  "message",
  (event) => {
    message = event.data

    switch (message.type) {
      case "resize_canvas":
        document.getElementById('game_frame').setAttribute("height", message.height)

        break

      case "authenticate":
        document.getElementById('game_frame').contentWindow.postMessage(
          {
            type: "authenticated",
            signedRequest: window.signedRequest
          },
          "*"
        )

        break

      case "ensure_login":
        $.get("/session/validate.json", function(data) {
          if (!data.signed_in) {
            window.location.href = "/account/sign_up"
          }
        })

        break
    }
  },
  false
);


import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if(window.enableSentry) {
  Sentry.init({
    dsn: "https://a292672d76a1463e828565f0963a7404@o114504.ingest.sentry.io/6242190",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}